import Vue from 'vue'
import router from './router/router.js'
import ElementUI from 'element-ui';
import store from './vuex/store.js'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import 'font-awesome/css/font-awesome.min.css'
// import config from '@/config/config.js'
import { goto_r } from './utils/check.js';

// 引入动态背景vue-particles
import VueParticles from 'vue-particles'  
Vue.use(VueParticles)



// const {
// 	base_config
// } = config;
// const {
// 	urls
// } = base_config;

Vue.use(ElementUI);

Vue.config.productionTip = false


Vue.prototype.$msg = (message, title = "警告", type = "warning") => {
	ElementUI.Notification({
		title,
		message,
		type,
		position: 'bottom-right'
	});
}




// https://www.lax8.com
// 单点登录核心  下发 token给 每个子系统
Vue.prototype.$sendToken = (e) => {
	localStorage.setItem('mx_token',e)
	// let promises = [];
	// urls.forEach((url) => {
	// 	let iframe = document.createElement("iframe");
	// 	iframe.style.display = 'none';
	// 	iframe.src = url;
	// 	document.body.append(iframe);
	// 	promises.push(new Promise((resolve) => {
	// 		iframe.onload = () => {
	// 			iframe.contentWindow.postMessage({mx_token:e}, url);
	// 			resolve();
	// 		};
	// 	}));
	// });
	// Promise.all(promises).then(() => {
	// 	setTimeout(() => {
	// 		urls.forEach(url => {
	// 			let iframe = document.querySelector(`iframe[src="${url}"]`);
	// 			if (iframe) {
	// 				iframe.remove();
	// 			}
	// 		});
	// 		
	// 	}, 1000);
	// });
	window.location.replace(goto_r());
}




new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')