<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {

		},
		methods: {


		},
		created() {


			window.addEventListener("beforeunload", () => {
				window.sessionStorage.setItem("mx_info", JSON.stringify(this.$store.state))
			})

			if (window.sessionStorage.getItem("mx_info")) {
				this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(window.sessionStorage.getItem(
					"mx_info"))))
				window.sessionStorage.removeItem('mx_info');
			}


		},
		destroyed() {}
	}
</script>

<style>
	@import 'assets/style/bootstrap.min.css';
	@import 'assets/style/main.min.css';

	.sign-img img{
		background: #fff;
	}

	#u_sign {
		/* background-image: url("./assets/picture/bg.jpg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover; */
		background: #eef4fd;

	}
	#particles-js {
		width: 100%;
		height: 100%;
		position: fixed;
	}


	.scale-placeholder.is_focus,
	input:focus~.scale-placeholder {
		transform: translateY(-20px);
		font-size: 11px;
	}
	.sign-img+.sign::before {
		content: '';
		position: absolute;
		top: -144px;
		left: 80px;
		width: 191px;
		height: 187px;
		background: url('./assets/picture/loginll.png') no-repeat center / 100%;
	}
	.modal-dialog{
		margin-top: 76px;
	}
	@media (max-width:996px) {
		.modal-dialog{
			margin-top: 106px!important;
		}
	}
	

	/* .header-slider-search .line-form-input:focus~.scale-placeholder,.header-slider-search .scale-placeholder.is-focus{transform:translateY(-32px);font-size:12px;color:#fff} */
</style>