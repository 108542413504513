export default {
	base_config: {
		baseURL: 'https://api.lax8.com',   //默认基地址 换成自己的接口地址
		// baseURL: 'http://127.0.0.1:8915', // 本地
		// goto_url:'https://www.lax8.com',
		// goto_url: 'http://127.0.0.1:8080/info',
		goto_url:'https:///sso.lax8.com/info',
		urls:['https://www.lax8.com']
	},
	wx_config: {
		wx_appid: 'wx56093b193928307f',
		wx_redirect: 'https://sso.lax8.com/login_wechat'

	},
	github_config: {
		// redirect_uri:'http://127.0.0.1:8915/login_github',  // 本地
		redirect_uri: 'https://sso.lax8.com/login_github',
		// client_id:'af594ab824d189fc8553',   // 本地
		client_id: '410e6c8fd8da925a23a6'

	},
	qq_config: {
		qq_redirect: 'https://sso.lax8.com/login_qq',
		qq_client_id: 102063607
	},
	gitee_config:{
		gitee_redirect:'https://sso.lax8.com/login_gitee',
		// gitee_redirect:'http://127.0.0.1:8080/login_gitee',
		gitee_client_id:'74c9f0f0f889cf042472af51f82306f8c635428afbdaf8173eb9df2780182692'
	},
	weibo_config:{
		weibo_client_id:1492251657,
		weibo_redirect_uri:'https://sso.lax8.com/login_weibo'
	},
	douyin_config:{
		douyin_client_id:'awwvgyeo3r67dpng',
		douyin_redirect_uri:'https://sso.lax8.com/login_douyin'
	}

}