import Vue from 'vue'
import VueRouter from 'vue-router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

// import store from '../vuex/store'
const MyLogin = () => import('@/pages/MyLogin.vue')
const MyInfo = () => import('@/pages/MyInfo.vue')
const NotFonud = () => import('@/pages/NotFonud.vue')
const MyBind = () => import('@/pages/MyBind.vue')
const MyMine = () => import('@/pages/MyMine.vue')
const LoginQQ = () => import('@/pages/LoginQQ.vue')
const LoginGitee = () => import('@/pages/LoginGitee.vue')
const LoginWechat = () => import('@/pages/LoginWechat.vue')
const LoginGithub = () => import('@/pages/LoginGithub.vue')
const LoginWeibo = () => import('@/pages/LoginWeibo.vue')
const LoginDouyin = () => import('@/pages/LoginDouyin.vue')

Vue.use(VueRouter)
const router = new VueRouter({
	mode: 'history',
	routes: [{
			path: '/',
			redirect: '/login',
			meta: {
				title: '慕旋网络统一认证中心-登录页面'
			}
		},
		{
			path: '/login',
			component: MyLogin,
			name:'MyLogin',
			meta: {
				title: '慕旋网络统一认证中心-登录页面'
			},
		},
		{
			path:'/bind',
			component:MyBind,
			name:'MyBind',
			meta:{
				title:'慕旋网络统一认证中心-绑定页面'
			}
		},
		{
			path:'/info',
			component:MyInfo,
			name:'MyInfo',
			redirect:'/info/mine',
			meta:{
				title:'慕旋网络统一认证中心-个人中心'
			},
			children:[{
				path:'mine',
				name:'MyMine',
				component:MyMine,
				meta:{title:'慕旋网络统一认证中心-个人中心'}	
			}]
		},
		
		{
			path:'/login_qq',
			component:LoginQQ,
			name:'LoginQQ',
			meta:{
				title:'慕旋网络统一认证中心-qq授权登录页面'
			}
		},
		{
			path:'/login_weibo',
			component:LoginWeibo,
			name:'LoginWeibo',
			meta:{
				title:'慕旋网络统一认证中心-微博授权登录页面'
			}
		},
		
		{
			path:'/login_gitee',
			component:LoginGitee,
			name:'LoginGitee',
			meta:{
				title:'慕旋网络统一认证中心-gitee授权登录页面'
			}
		},
		{
			path:'/login_wechat',
			component:LoginWechat,
			name:'LoginWechat',
			meta:{
				title:'慕旋网络统一认证中心-微信授权登录页面'
			}
		},
		{
			path:'/login_github',
			component:LoginGithub,
			name:'LoginGithub',
			meta:{
				title:'慕旋网络统一认证中心-github授权登录页面'
			}
		},
		{
			path:'/login_douyin',
			component:LoginDouyin,
			name:'LoginDouyin',
			meta:{
				title:'慕旋网络统一认证中心-抖音授权登录页面'
			}
		},
		
		{
			path:'*',
			component:NotFonud,
			name:'NotFonud',
			meta:{
				title:'慕旋统一认证中心-404页面'
			}
		},
		
	]
})


router.beforeEach(async(to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.done()
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})


export default router