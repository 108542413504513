import config from '@/config/config.js'
const {base_config} = config;
const {goto_url} = base_config;

// 检查参数是否正确
export const checkparams = (...params) => {
	return params.every(param => param !== null && param !== undefined && param !== '');
}

// 检查邮箱的合法性
export const checkemail = (email) => {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(email);
}
/^[a-zA-Z]\w{4,15}$/

// 检查用户名的合法性 4到30位（字母，数字，下划线，减号）
export const checkusername = (username) => {
	const reg = /^[a-zA-Z0-9_-]{4,30}$/;
	return reg.test(username);
}

// 两次密码作对比
export const checkrepassword = (password, repassword) => {
	return password === repassword;
}

// 密码6-18位即可
export const checkpassword = (password) => {
	let size = password.length;
	return size >= 6 && size <= 18;
}

// 初步检查验证码 6为且全为数字
export const checkprecatch = (captch) => {
	const reg = /^\d{6}$/;
	return reg.test(captch);
}

// 登陆成功后跳转的链接
export const goto_r = () => {
	let r=localStorage.getItem('r');
	let mx_token=localStorage.getItem('mx_token');
	let url = goto_url;
	// console.log(r);
	if (r !== null && r !== undefined && r !== '') {
		url = r+'?mx_token='+mx_token;
	}
	localStorage.removeItem('r');
	return url;
}

// 检查uuid格式
export const checkUUID=(uuid)=> {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}