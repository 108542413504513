import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		extra:{},
		userinfo:{}
	},
	getters: {

	},
	mutations: {
		setExtra(state, extra) {
			state.extra = extra;
		},
		setUserinfo(state,info){
			state.userinfo = info;
		},
		setUseravatar(state,avatar){
			state.userinfo.avatar_url=avatar;
		},
		setUsername(state,name){
			state.userinfo.display_name=name;
		}
	},
	actions: {
		
	}
})
export default store